import { initLogger } from 'logger';
import { initRum } from 'rum';
import { SurveyScript } from 'survey';

import { Layout } from '~/layout/Layout';

import { AppProvider } from '../src/styles/providers/app';

interface Props {
  Component: React.ElementType;
  pageProps: Record<string, unknown>;
}

initRum({ service: 'switching' });
initLogger({ service: 'switching' });

const MyApp = ({ Component, pageProps }: Props) => {
  return (
    <AppProvider>
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <SurveyScript />
    </AppProvider>
  );
};

export default MyApp;
