import { ButtonBase, styled } from '@mui/material';

import { Theme } from '../../theme';
import { ButtonProps, Color, Size, Variant } from './types';

const colors = {
  grey: {
    dark: '#3D3D3D',
    light: '#FBFBFB',
    main: '#E7E7E7',
  },
};

export const getColorStyles = (color: Color, { palette }: Theme) => {
  switch (color) {
    case 'white':
      return {
        ':hover': { color: palette.primary.main },
        background: palette.common.white,
        color: palette.common.black,
      };
    case 'grey':
      return {
        ':hover': { background: colors.grey.light },
        background: colors.grey.main,
        color: palette.info.main,
      };
    case 'lightGrey':
      return {
        ':hover': { background: colors.grey.main },
        background: colors.grey.light,
        color: colors.grey.dark,
      };
    case 'primary':
      return {
        ':hover': { background: palette.primary.dark },
        background: palette.primary.main,
        color: palette.common.white,
      };
    default:
      return {
        ':hover': { background: palette[color].light },
        background: palette[color].main,
        color: palette[color].dark,
      };
  }
};

export const getSizeStyles = (size: Size, variant: Variant, theme: Theme) => {
  switch (variant) {
    case 'square':
      return {
        ...(size === 'sm' && {
          fontSize: 13,
          height: 54,
          width: 54,
        }),
        ...(size === 'md' && {
          fontSize: 16,
          height: 68,
          width: 68,
        }),
      };
    default:
      return {
        ...(size === 'sm' && {
          fontSize: 13,
          padding: theme.spacing(0.55, 1.5),
        }),
        ...(size === 'md' && {
          fontSize: 15,
          padding: theme.spacing(1.1, 3),
        }),
        ...(size === 'lg' && {
          fontSize: 16,
          padding: theme.spacing(1.4, 3.5),
        }),
      };
  }
};

export const getVariantStyles = (variant: Variant, theme: Theme) => ({
  ...(variant === 'primary' && {
    borderRadius: 14,
    fontWeight: 'bold',
    ...getColorStyles('primary', theme),
  }),
  ...(variant === 'secondary' && {
    borderRadius: 14,
    fontWeight: 'bold',
    ...getColorStyles('mintGreen', theme),
  }),
  ...(variant === 'tertiary' && {
    borderRadius: 14,
    fontWeight: 'bold',
    ...getColorStyles('grey', theme),
  }),
  ...(variant === 'square' && {
    borderRadius: 14,
    boxShadow: theme.customShadows.main,
    ...getColorStyles('white', theme),
  }),
});

export const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'noWrap',
})<ButtonProps>(({ theme, disabled, color, size = 'md', variant = 'primary', fullWidth, noWrap = true }) => ({
  border: 'none',
  cursor: 'pointer',
  ...(disabled && {
    '&.Mui-disabled': {
      background: theme.palette.grey[200],
      color: theme.palette.info.light,
      cursor: 'default',
    },
  }),
  lineHeight: 1.6,
  verticalAlign: 'middle',
  whiteSpace: noWrap ? 'nowrap' : 'normal',
  width: fullWidth ? '100%' : 'none',
  ...getVariantStyles(variant, theme),
  ...(color && getColorStyles(color, theme)),
  ...getSizeStyles(size, variant, theme),
}));
