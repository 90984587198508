import { styled, Table as MuiTable, TableProps, tableRowClasses } from '@mui/material';

interface Props extends TableProps {
  verticalRowSpacing?: number;
}

const DEFAULT_ROW_SPACING = 2;
const MAX_ROW_HEIGHT = 90;

const StyledTable = styled(MuiTable, {
  shouldForwardProp: (prop) => prop !== 'verticalRowSpacing',
})<Props>(({ verticalRowSpacing, size }) => ({
  borderCollapse: 'separate',
  borderSpacing: `0 ${verticalRowSpacing}px`,
  [`.${tableRowClasses.root}`]: {
    height: size === 'small' ? undefined : MAX_ROW_HEIGHT,
  },
}));

export const Table = ({ verticalRowSpacing = DEFAULT_ROW_SPACING, ...rest }: Props) => (
  <StyledTable verticalRowSpacing={verticalRowSpacing} {...rest} />
);
