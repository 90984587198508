import { styled } from '@mui/system';

import TextField, { TextFieldProps } from './TextField';

export type PhoneFieldProps = TextFieldProps;

const PhoneField = styled(TextField)(() => ({
  '& fieldset': {
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
  },
}));

export default PhoneField;
