import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Redirect } from './Redirect';

export const RedirectToHomepage = () => {
  const router = useRouter();
  const [language, setLanguage] = useState('');

  useEffect(() => {
    if (!router.isReady) return;

    const { lang } = router.query;

    if (typeof lang === 'string' && lang?.length) {
      setLanguage(lang);
    }
  }, [router.isReady, router]);

  return <Redirect to={`http://toki.bg/${language}${language ? '/' : ''}#plans`} />;
};
