import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'next-export-i18n'; // TODO: remove i18n

import { FacebookRounded, Instagram, LinkedIn, LocalPhone, LocationOn, MailOutline, YouTube } from '../icons';
import { Box } from './Box/Box';
import { Container } from './Container';
import { Link } from './Link';
import { Typography } from './Typography/Typography';

export const Footer = ({ email }: { email?: string }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const contactList = [
    { icon: <LocationOn />, text: t('shared.company.address') },
    { icon: <LocalPhone />, text: t('shared.company.phone') },
    { icon: <MailOutline />, text: email },
  ];
  const socialMedia = [
    { icon: <FacebookRounded />, url: 'https://www.facebook.com/TokiPower/' },
    { icon: <YouTube />, url: 'https://www.youtube.com/channel/UC88ER749i5WeRQrxkfWGyFw' },
    { icon: <Instagram />, url: 'https://www.instagram.com/toki.bg/' },
    { icon: <LinkedIn />, url: 'https://www.linkedin.com/company/toki' },
  ];

  return (
    <Box
      width="100%"
      mt="auto"
      sx={{ background: theme.palette.primary.dark, color: theme.palette.common.white }}
      textAlign="center"
    >
      <Container>
        <Typography variant="h4">{t('shared.company.name')}</Typography>
        <Stack direction="column" alignItems="center" gap={1} my={3}>
          {contactList.map(({ text, icon }) => (
            <Stack direction="row" gap={1} key={text}>
              {icon}
              <Typography variant="body1">{text}</Typography>
            </Stack>
          ))}
        </Stack>
        <Box py={2} display="flex" justifyContent="center">
          {socialMedia.map(({ url, icon }) => (
            <Link href={url} newTab key={url}>
              <Box
                width={40}
                height={40}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.grey[100],
                  },
                  bgcolor: theme.palette.common.white,
                  borderRadius: '50%',
                  color: theme.palette.primary.light,
                  mx: 1,
                }}
              >
                {icon}
              </Box>
            </Link>
          ))}
        </Box>
      </Container>

      <Box sx={{ backgroundColor: theme.palette.primary.light }}>
        <Container>
          <Box display="flex" justifyContent="space-between" sx={{ flexDirection: { md: 'row', xs: 'column' } }}>
            <Typography>{`${t('shared.company.toki')} ${new Date().getFullYear()}`}</Typography>
            <Box display="flex" justifyContent="space-between">
              <Link
                color="common.white"
                href="https://toki.bg/231220_%d0%be%d0%b1%d1%89%d0%b8-%d1%83%d1%81%d0%bb%d0%be%d0%b2%d0%b8%d1%8f_%d0%be%d1%82%d1%80%d0%b8%d1%86%d0%b0%d1%82%d0%b5%d0%bb%d0%bd%d0%b8-%d1%86%d0%b5%d0%bd%d0%b8-%d0%bf%d0%bb%d0%b0%d1%89%d0%b0/"
                mr={2}
              >
                {t('shared.common.termsOfUse')}
              </Link>
              <Link
                href="https://toki.bg/%d0%bf%d0%be%d0%bb%d0%b8%d1%82%d0%b8%d0%ba%d0%b0-%d0%b7%d0%b0-%d0%bf%d0%be%d0%b2%d0%b5%d1%80%d0%b8%d1%82%d0%b5%d0%bb%d0%bd%d0%be%d1%81%d1%82/"
                color="common.white"
              >
                {t('shared.common.privacyPolicy')}
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
