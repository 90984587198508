import { Box, MobileStepper } from '@mui/material';
import Carousel from 'nuka-carousel';
import React, { ReactNode, useState } from 'react';

enum SwipeDirection {
  Left = 'left',
  Right = 'right',
}
interface Props {
  elements: ReactNode[];
}

const CELL_SPACING = 16;

const MobileSwiper = ({ elements }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [changed, setChanged] = useState(false);
  const [direction, setDirection] = useState<SwipeDirection | null>(null);
  const [initialTouchX, setInitialTouchX] = useState<number | null>(null);

  const handleDragChange = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!e.touches) return;

    const { clientX } = e.touches[0];

    if (initialTouchX === null) {
      setInitialTouchX(clientX);
    } else {
      const deltaX = clientX - initialTouchX;
      const currDirection = deltaX > 0 ? SwipeDirection.Right : SwipeDirection.Left;

      if ((changed && direction === currDirection) || Math.abs(deltaX) <= 70) return;

      if (deltaX > 0) {
        setActiveIndex(activeIndex === 0 ? 0 : activeIndex - 1);
        setChanged(true);
        setDirection(SwipeDirection.Right);
      } else {
        const lastIndex = elements.length - 1;
        setActiveIndex(activeIndex === lastIndex ? lastIndex : activeIndex + 1);
        setChanged(true);
        setDirection(SwipeDirection.Left);
      }
    }
  };

  return (
    <div>
      <Carousel
        withoutControls
        cellSpacing={CELL_SPACING}
        dragThreshold={0.3}
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        style={{ left: (CELL_SPACING / 2) * -1, paddingBottom: 2, width: `calc(100% + ${CELL_SPACING}px)` }}
        // Can't access the event with onDrag
        onDragStart={(e) => handleDragChange(e as React.TouchEvent<HTMLDivElement>)}
        onDragEnd={() => {
          setInitialTouchX(null);
          setDirection(null);
          setChanged(false);
        }}
      >
        {elements}
      </Carousel>
      <Box display="flex" justifyContent="center">
        <MobileStepper
          variant="dots"
          steps={elements.length}
          position="static"
          activeStep={activeIndex}
          nextButton={null}
          backButton={null}
          sx={{
            '& .MuiMobileStepper-dot': {
              height: 16,
              mx: 1.5,
              width: 16,
            },
            backgroundColor: 'transparent',
            mt: 2,
          }}
        />
      </Box>
    </div>
  );
};

export default MobileSwiper;
