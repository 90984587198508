import { styled, TableCell as MuiTableCell, tableCellClasses, TableCellProps } from '@mui/material';

type Props = TableCellProps;

const StyledTableCell = styled(MuiTableCell)<Props>(() => ({
  alignItems: 'left',
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

export const TableCell = ({ ...rest }: Props) => <StyledTableCell {...rest} />;
