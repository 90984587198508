import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import { forwardRef, InputHTMLAttributes } from 'react';
import { FILE_FORMATS } from 'types';

import { AttachFile } from '../../icons';
import { Button } from '../Button';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  fileFormats?: FILE_FORMATS[];
  helperText?: string;
  label: string;
}
export const FileSelectField = forwardRef<HTMLInputElement, Props>(
  ({ id, error, fileFormats = [FILE_FORMATS.PDF], helperText, label, value, ...rest }, ref) => {
    const acceptedFormats = fileFormats.join(', ');
    const formHelpText = error || helperText;

    return (
      <FormControl error={Boolean(error)}>
        <Box component="div" display="flex">
          <Button size="sm" component="label" startIcon={<AttachFile fontSize="small" />}>
            {label}
            <input type="file" accept={acceptedFormats} hidden id={id || label} value="" {...rest} ref={ref} />
          </Button>
          {!error && value && (
            <Typography mt={0.7} ml={1}>
              {value}
            </Typography>
          )}
        </Box>

        {formHelpText && <FormHelperText>{formHelpText}</FormHelperText>}
      </FormControl>
    );
  }
);
