import icons from 'country-flag-icons/react/3x2';
import logger from 'logger';
import React from 'react';

import { CountryIsoCode } from '../config';

export const Flag = ({ isoCode }: { isoCode: CountryIsoCode }) => {
  const flag = { ...icons }[`${isoCode.toUpperCase()}`];

  if (!flag) {
    logger.debug(`Flag not found for country:${isoCode}`);
    return null;
  }
  return React.createElement(flag);
};
